// material-ui
import {
   Stack,
   Typography,
   DialogContent,
   DialogActions,
   Divider,
   MenuItem,
   OutlinedInput,
   Select,
   FormControl,
   FormHelperText,
   Tooltip
} from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import Button from 'components/@extended/LoadingButton';
import { useTranslation } from 'utils/locales/utilityFunctions';

// ==============================|| QUESTIONNAIRE ||============================== //

export type QuestionnaireAnswers = {
   role: string[];
   roleIds: string[];
   estates: string;
   estatesId: string;
   hectares: string;
   hectaresId: string;
   purposes: string[];
   purposesIds: string[];
};

interface QuestionnaireProps {
   handleNext: () => void;
   handleBack: () => void;
   setErrorIndex: (i: number | null) => void;
   questionnaireAnswers: any;
   disableBackButton?: boolean;
   setQuestionnaireAnswers: (values: QuestionnaireAnswers) => void;
}

const Questionnaire = ({ handleNext, handleBack, setErrorIndex, questionnaireAnswers, disableBackButton, setQuestionnaireAnswers }: QuestionnaireProps) => {
   // --- Hooks --- //
   const { translate } = useTranslation();

   // --- DATA --- //
   // Validation schema
   const validationSchema = yup.object({
      role: yup.array().min(1, translate("onboarding-questionnaire-validation-role")),
      estates: yup.string().required(translate("onboarding-questionnaire-validation-estates")),
      hectares: yup.string().required(translate("onboarding-questionnaire-validation-hectares")),
      purposes: yup.array().min(1, translate("onboarding-questionnaire-validation-purposes"))
   });
   const roles = [
      { id: "onboarding-questionnaire-roles-admin-item", item: translate("onboarding-questionnaire-roles-admin-item"), tooltip: translate("onboarding-questionnaire-roles-admin-tooltip") },
      { id: "onboarding-questionnaire-roles-forester-item", item: translate("onboarding-questionnaire-roles-forester-item"), tooltip: translate("onboarding-questionnaire-roles-forester-tooltip") },
      { id: "onboarding-questionnaire-roles-consultant-item", item: translate("onboarding-questionnaire-roles-consultant-item"), tooltip: translate("onboarding-questionnaire-roles-consultant-tooltip") },
      { id: "onboarding-questionnaire-roles-farmer-item", item: translate("onboarding-questionnaire-roles-farmer-item"), tooltip: translate("onboarding-questionnaire-roles-farmer-tooltip") },
      { id: "onboarding-questionnaire-roles-researcher-item", item: translate("onboarding-questionnaire-roles-researcher-item"), tooltip: translate("onboarding-questionnaire-roles-researcher-tooltip") },
      { id: "onboarding-questionnaire-roles-other-item", item: translate("onboarding-questionnaire-roles-other-item"), tooltip: translate("onboarding-questionnaire-roles-other-tooltip") }
   ];

   const estates = [
      { id: "onboarding-questionnaire-amount-1", item: translate("onboarding-questionnaire-amount-1") },
      { id: "onboarding-questionnaire-amount-2-5", item: translate("onboarding-questionnaire-amount-2-5") },
      { id: "onboarding-questionnaire-amount-6-10", item: translate("onboarding-questionnaire-amount-6-10") },
      { id: "onboarding-questionnaire-amount-11-20", item: translate("onboarding-questionnaire-amount-11-20") },
      { id: "onboarding-questionnaire-amount-21-50", item: translate("onboarding-questionnaire-amount-21-50") },
      { id: "onboarding-questionnaire-amount-51+", item: translate("onboarding-questionnaire-amount-51+") },
      { id: "onboarding-questionnaire-amount-non", item: translate("onboarding-questionnaire-amount-non") },
   ];

   const hectares = [
      { id: "onboarding-questionnaire-size-under-10-ha", item: translate("onboarding-questionnaire-size-under-10-ha") },
      { id: "onboarding-questionnaire-size-10-50", item: translate("onboarding-questionnaire-size-10-50") },
      { id: "onboarding-questionnaire-size-51-150", item: translate("onboarding-questionnaire-size-51-150") },
      { id: "onboarding-questionnaire-size-151-500", item: translate("onboarding-questionnaire-size-151-500") },
      { id: "onboarding-questionnaire-size-501-1000", item: translate("onboarding-questionnaire-size-501-1000") },
      { id: "onboarding-questionnaire-size-over-1000", item: translate("onboarding-questionnaire-size-over-1000") },
   ];

   const purposes = [
      { id: "onboarding-questionnaire-purpose-management", item: translate("onboarding-questionnaire-purpose-management") },
      { id: "onboarding-questionnaire-purpose-nature", item: translate("onboarding-questionnaire-purpose-nature") },
      { id: "onboarding-questionnaire-purpose-analysis", item: translate("onboarding-questionnaire-purpose-analysis") },
      { id: "onboarding-questionnaire-purpose-sharing", item: translate("onboarding-questionnaire-purpose-sharing") },
      { id: "onboarding-questionnaire-purpose-stakeholder", item: translate("onboarding-questionnaire-purpose-stakeholder") },
      { id: "onboarding-questionnaire-purpose-planning", item: translate("onboarding-questionnaire-purpose-planning") },
      { id: "onboarding-questionnaire-purpose-other", item: translate("onboarding-questionnaire-purpose-other") },
   ];

   const formik = useFormik({
      initialValues: {
         role: questionnaireAnswers.role,
         roleIds: questionnaireAnswers.roleIds,
         estates: questionnaireAnswers.estates,
         estatesId: questionnaireAnswers.estatesId,
         hectares: questionnaireAnswers.hectares,
         hectaresId: questionnaireAnswers.hectaresId,
         purposes: questionnaireAnswers.purposes,
         purposesIds: questionnaireAnswers.purposesIds,
      },
      validationSchema,
      onSubmit: (values: QuestionnaireAnswers) => {
         // Map answers to ids
         let roleIds: string[] = [];
         let estatesId: string = "";
         let hectaresId: string = "";
         let purposesIds: string[] = [];
         values.role.forEach(el => {
            const r = roles.filter(role => role.item === el);
            roleIds.push(r[0].id);
         });
         const e = estates.filter(est => est.item === values.estates);
         estatesId = e[0].id;
         const h = hectares.filter(est => est.item === values.hectares);
         hectaresId = h[0].id;
         values.purposes.forEach(el => {
            const p = purposes.filter(purp => purp.item === el);
            purposesIds.push(p[0].id);
         });
         setQuestionnaireAnswers({ ...values, roleIds, estatesId, hectaresId, purposesIds });
         handleNext();
      }
   });

   return (
      <>
         <DialogContent>
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
               {translate("onboarding-questionnaire-title")}
            </Typography>

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
               {translate("onboarding-questionnaire-text")}
            </Typography>

            <form onSubmit={formik.handleSubmit} id="validation-form">
               <Stack spacing={3} sx={{ mt: 2 }}>
                  <Stack spacing={1}>
                     <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {translate("onboarding-questionnaire-roles-title")}
                     </Typography>

                     <FormControl fullWidth error={formik.touched.role && Boolean(formik.errors.role)}>
                        <Select
                           labelId="question-role"
                           id="question-role-select"
                           multiple
                           {...formik.getFieldProps('role')}
                           input={<OutlinedInput color="secondary" />}
                           renderValue={(selected) => {
                              if (selected.length === 0) {
                                 return <Typography color="grey.400">{translate("onboarding-questionnaire-roles-placeholder")}</Typography>;
                              }
                              return selected.join(', ');
                           }}
                           displayEmpty
                           onBlur={formik.handleBlur}
                           error={formik.touched.role && Boolean(formik.errors.role)}
                        >
                           {roles.map((role) => (
                              <MenuItem
                                 key={role.id}
                                 value={role.item}
                                 // id={role.id}
                                 sx={{
                                    '&.Mui-selected': {
                                       bgcolor: 'primary.lighter'
                                    },
                                    '&.MuiMenuItem-root:hover': {
                                       bgcolor: 'secondary.lighter'
                                    }
                                 }}
                              >
                                 <Tooltip title={role.tooltip}>
                                    <span>{role.item}</span>
                                 </Tooltip>
                              </MenuItem>
                           ))}
                        </Select>

                        {formik.touched.role && formik.errors.role ? (
                           <FormHelperText>
                              {typeof formik.errors.role === 'string' ? formik.errors.role : 'Error'}
                           </FormHelperText>
                        ) : null}
                     </FormControl>
                  </Stack>

                  <Stack spacing={1}>
                     <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {translate("onboarding-questionnaire-amount-title")}
                     </Typography>

                     <FormControl fullWidth error={formik.touched.estates && Boolean(formik.errors.estates)}>
                        <Select
                           labelId="question-estates"
                           id="question-estates-select"
                           {...formik.getFieldProps('estates')}
                           input={<OutlinedInput color="secondary" />}
                           renderValue={(selected) => {
                              if (selected.length === 0) {
                                 return <Typography color="grey.400">{translate("onboarding-questionnaire-amount-placeholder")}</Typography>;
                              }
                              return selected;
                           }}
                           displayEmpty
                        >
                           {estates.map((range) => (
                              <MenuItem
                                 key={range.id}
                                 value={range.item}
                                 sx={{
                                    '&.Mui-selected': {
                                       bgcolor: 'primary.lighter'
                                    },
                                    '&.MuiMenuItem-root:hover': {
                                       bgcolor: 'secondary.lighter'
                                    }
                                 }}
                              >
                                 {range.item}
                              </MenuItem>
                           ))}
                        </Select>

                        {formik.touched.estates && formik.errors.estates ? (
                           <FormHelperText>
                              {typeof formik.errors.estates === 'string' ? formik.errors.estates : 'Error'}
                           </FormHelperText>
                        ) : null}
                     </FormControl>
                  </Stack>

                  <Stack spacing={1}>
                     <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {translate("onboarding-questionnaire-size-title")}
                     </Typography>

                     <FormControl fullWidth error={formik.touched.hectares && Boolean(formik.errors.hectares)}>
                        <Select
                           labelId="question-hectares"
                           id="question-hectares-select"
                           {...formik.getFieldProps('hectares')}
                           input={<OutlinedInput color="secondary" />}
                           renderValue={(selected) => {
                              if (selected.length === 0) {
                                 return <Typography color="grey.400">{translate("onboarding-questionnaire-size-placeholder")}</Typography>;
                              }
                              return selected;
                           }}
                           displayEmpty
                        >
                           {hectares.map((range) => (
                              <MenuItem
                                 key={range.id}
                                 value={range.item}
                                 sx={{
                                    '&.Mui-selected': {
                                       bgcolor: 'primary.lighter'
                                    },
                                    '&.MuiMenuItem-root:hover': {
                                       bgcolor: 'secondary.lighter'
                                    }
                                 }}
                              >
                                 {range.item}
                              </MenuItem>
                           ))}
                        </Select>

                        {formik.touched.hectares && formik.errors.hectares ? (
                           <FormHelperText>
                              {typeof formik.errors.hectares === 'string' ? formik.errors.hectares : 'Error'}
                           </FormHelperText>
                        ) : null}
                     </FormControl>
                  </Stack>

                  <Stack spacing={1}>
                     <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {translate("onboarding-questionnaire-purpose-title")}
                     </Typography>

                     <FormControl fullWidth error={formik.touched.purposes && Boolean(formik.errors.purposes)}>
                        <Select
                           labelId="question-purposes"
                           id="question-purposes-select"
                           multiple
                           {...formik.getFieldProps('purposes')}
                           input={<OutlinedInput color="secondary" />}
                           renderValue={(selected) => {
                              if (selected.length === 0) {
                                 return <Typography color="grey.400">{translate("onboarding-questionnaire-purpose-placeholder")}</Typography>;
                              }
                              return selected.join(', ');
                           }}
                           displayEmpty
                        >
                           {purposes.map((purpose) => (
                              <MenuItem
                                 key={purpose.id}
                                 value={purpose.item}
                                 sx={{
                                    '&.Mui-selected': {
                                       bgcolor: 'primary.lighter'
                                    },
                                    '&.MuiMenuItem-root:hover': {
                                       bgcolor: 'secondary.lighter'
                                    }
                                 }}
                              >
                                 {purpose.item}
                              </MenuItem>
                           ))}
                        </Select>

                        {formik.touched.purposes && formik.errors.purposes ? (
                           <FormHelperText>
                              {typeof formik.errors.purposes === 'string' ? formik.errors.purposes : 'Error'}
                           </FormHelperText>
                        ) : null}
                     </FormControl>
                  </Stack>
               </Stack>
            </form>
         </DialogContent >

         <Divider />

         <DialogActions>
            {/* ACTION */}
            <Stack direction="row" spacing={1} justifyContent='space-between' sx={{ width: 1, pl: 1.5, pr: 1.5, py: 0.75 }}>
               <Button color="secondary" disabled={disableBackButton} onClick={handleBack}>
                  {translate("back")}
               </Button>

               <Button
                  variant="contained"
                  type="button"
                  disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                  loading={formik.isSubmitting}
                  onClick={() => {
                     setErrorIndex(3);
                     formik.handleSubmit();
                  }}
                  sx={{
                     bgcolor: 'primary.light',
                     '&.Mui-disabled': {
                        bgcolor: 'secondary.lighter',
                        color: 'secondary.main'
                     }
                  }}
               >
                  {translate('next')}
               </Button>
            </Stack>
         </DialogActions>
      </>
   );
};

export default Questionnaire;
