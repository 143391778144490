import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Tooltip, useMediaQuery } from '@mui/material';

// material-ui icons
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import CommentIcon from '@mui/icons-material/CommentRounded';
import EditIcon from '@mui/icons-material/EditRoad';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsRounded';

// project import
import useConfig from 'hooks/useConfig';
// import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
import Localization from './Localization';
// import Notification from './Notification';
import MobileSection from './MobileSection';
// import MegaMenuSection from './MegaMenuSection';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import navigation from 'menu-items';
import EstatesAndMapSelecter from 'components/estatesAndMapSelecter';
import ShareData from 'components/shareData';
import useAppFunctionalityConfig from 'hooks/useAppFunctionalityConfig';

import { MapProjectRoles } from 'types/auth';
import useEstatesAndMaps from 'hooks/useEstatesAndMaps';
import { useTranslation } from 'utils/locales/utilityFunctions';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
   // --- Hooks --- //
   const { i18n } = useConfig();
   const { config: appConfig } = useAppFunctionalityConfig();
   const { translate } = useTranslation();
   const { selectedMapId, selectedProjectId } = useEstatesAndMaps();
   const location = useLocation();
   const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
   const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
   const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

   // --- State and refs --- //

   // --- Effects --- //

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const localization = useMemo(() => <Localization />, [i18n]);
   // const megaMenu = useMemo(() => <MegaMenuSection />, []);

   const permissionIcon = mapPermission(appConfig.layout.currentMapRole, appConfig.layout.currentProjectRole, selectedMapId !== null, selectedProjectId !== null, translate);

   const showProfile = appConfig.layout.headerProfile !== "hidden";
   const showMapRole = appConfig.layout.headerMapRole !== "hidden";

   return (
      <>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', px: 1, flex: 1 }}>
               {!matchesMd && <Breadcrumbs navigation={navigation} title={false} titleBottom={false} card={false} divider={false} />}

               {location.pathname === '/map-page' &&
                  <>
                     <Box sx={{
                        flex: matchesMd ? 1 : 0,
                        maxWidth: matchesMd ? '57vw' : undefined,  // <-- Added this line to set maximum width
                        overflowX: matchesMd ? 'hidden' : undefined  // <-- Added this to prevent any horizontal scrolling 
                     }}>
                        <EstatesAndMapSelecter parent='map' />
                     </Box>

                     {location.pathname === '/map-page' && <>
                        <>
                           {(!matchesSm && selectedMapId && showMapRole) && <>
                              <Box sx={{ bgcolor: '#D6D6D6', width: '1px', height: '36px', mx: 1 }} />
                              {permissionIcon}
                           </>}

                           {showMapRole && <Box sx={{ bgcolor: '#D6D6D6', width: '1px', height: '36px', mx: 1 }} />}

                           <ShareData withButton={true} withAudience={true} withPersonSelector={true} />
                        </>
                     </>}
                  </>
               }

               {/* {!matchesXs && <Search />} */}
            </Box>

            {/* {!matchesXs && megaMenu} */}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               {/* Large screens */}
               {!matchesXs && <>
                  {localization}

                  { showProfile && <Box sx={{ bgcolor: '#D6D6D6', width: '1px', height: '36px', ml: 1, mr: 0.2 }} /> }

                  { showProfile && <Profile /> }
               </>}

               {/* Mobile */}
               {matchesMd && <MobileSection showProfile={showProfile} />}

               {/* {matchesXs && <Box sx={{ width: '100%', ml: 1, bgcolor: 'blue' }} />} */}

               {/* <Notification /> */}
            </Box>
         </Box >
      </>
   );
};

// --- Helper functions --- //
const mapPermission = (mapRole: MapProjectRoles, projectRole: MapProjectRoles, selectedMap: boolean, selectedProject: boolean, translate: (key: string) => string) => {
   const defaultIcon = (
      <Tooltip title={translate("map-role-not-selected-tooltip")}>
         <VisibilityIcon fontSize='small' color='secondary' />
      </Tooltip>);
   if (!selectedMap && !selectedProject) return defaultIcon;
   let role = mapRole;
   if (selectedProject) role = projectRole;
   // Return icon based on role
   switch (role) {
      case 'viewer': return (
         <Tooltip title={translate("map-role-viewer-icon-tooltip")}>
            <VisibilityIcon fontSize='small' color='secondary' />
         </Tooltip>
      );
      case 'commentator': return (
         <Tooltip title={translate("map-role-commentator-icon-tooltip")}>
            <CommentIcon fontSize='small' color='secondary' />
         </Tooltip>
      );
      case 'editor': return (
         <Tooltip title={translate("map-role-editor-icon-tooltip")}>
            <EditIcon fontSize='small' color='secondary' />
         </Tooltip>
      );
      case 'admin': return (
         <Tooltip title={translate("map-role-admin-icon-tooltip")}>
            <AdminPanelSettingsIcon fontSize='small' color='secondary' />
         </Tooltip >
      );
      default: return defaultIcon;
   }
};

export default HeaderContent;
