// General config
import { DefaultConfigProps } from 'types/config';

// ==============================|| GENERAL APP CONFIG  ||============================== //
export const FT_ORG_ID = "MaIA81uR7vgbxhbywk4p";
export const APP_URL = "https://app.forsler.com";
export const ERROR_LOG_ENABLED = true;
export const DEFAULT_FILES_BUCKET = "gs://forsler-prod_app_files";

// ==============================|| THIRD PARTY CONFIG  ||============================== //
// --- Firebase config --- //
export const FIREBASE_API = require("./firebase-prod.json");
export const CLOUD_FUNCTIONS_URL = "https://europe-west3-forsler-prod.cloudfunctions.net/";
export const INTERNAL_API_BASE_URL = "https://foresting-tomorrow-internal-4yr7fia4nq-ey.a.run.app";

// --- Product fruits config --- //
export const PF_WORKSPACE_CODE = "EcwDOIyEtb9ljkdl";
export const CRISP_KEY = "485edb08-b17e-4fa6-82b3-8287d623a09f";

// --- Mixpanel config --- //
export const MP_TOKEN = "fd061d7bcc230ad830ff968f26c064dc";
export const MP_DEBUG = false;

// ==============================|| THEME CONFIG  ||============================== //
const config: DefaultConfigProps = {
    defaultPath: '/home-page',
    fontFamily: `'Inter', sans-serif`,
    i18n: 'da',
    miniDrawer: false,
    container: false,
    mode: 'light',
    presetColor: 'theme3',
    themeDirection: 'ltr'
};

export default config;
