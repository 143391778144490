import React, { createContext, useEffect, useReducer, useRef } from "react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

// Project import
import {
  APP_SETTINGS_RESET_STATE,
  SET_USER_LANGUAGE,
  SET_MAP_BACKGROUND_LAYER,
  SET_MAP_OVERLAY_LAYERS,
  SET_MAP_CATEGORIES,
  SET_MAP_CATEGORY_FILTER_STATE,
  SET_MAP_DISPLAY_TOTAL_VALUES,
  SET_LABELS_ACTIVE,
  SET_TOOLTIPS_ACTIVE,
  SET_INIT_STATE,
  SET_TRIAL_SUBSCRIPTION,
} from "store/reducers/actions";
import appSettingsReducer from "store/reducers/appSettings";
import { firestore as db } from "contexts/FirebaseContext";
import { AppSettingsContextType } from "types/appSettings";
import { I18n } from "config/config-general";
import useConfig from "hooks/useConfig";
import { AppConfigSubNames } from "types/appFunctionality";

const initialState = {
  trialSubscription: "unknown",
  initializing: true,
  userLanguage: null,
  map: {
    backgroundLayer: null,
    overlayLayers: null,
    categories: null,
    categoryFilterState: null,
    pointLabelsActive: true,
    lineLabelsActive: false,
    polygonLabelsActive: true,
    pointTooltipsActive: true,
    lineTooltipsActive: true,
    polygonTooltipsActive: true,
    displayTotalValues: false,
  },
};

const AppSettingsContext = createContext<AppSettingsContextType | null>(null);

export function AppSettingsProvider({ children }: { children: React.ReactNode }) {
  // --- Hooks --- //
  // const { isLoggedIn, user, userOrg, orgPrivateData } = useAuth();
  const { onChangeLocalization } = useConfig();
  const [state, dispatch] = useReducer(appSettingsReducer, initialState);

  // --- State and reference --- //
  const userIdRef = useRef<string | null>(null);
  const userAppSettingsFetchedRef = useRef<boolean>(false);

  // --- Effects --- //
  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  // Set user app settings on firestore
  useEffect(() => {
    if (userIdRef.current) {
      // derive user app settings from state and remove itmes not needed
      const { ...restMap } = state.map;
      // Settings object to be updated
      let settingsObj: any = {
        map: restMap,
      };
      if (state.userLanguage) {
        settingsObj.userLanguage = state.userLanguage;
      }
      if (state.trialSubscription) {
        settingsObj.trialSubscription = state.trialSubscription;
      }
      updateUserAppSettings(userIdRef.current, settingsObj);
      userAppSettingsFetchedRef.current = true;
    }
  }, [state]);

  // --- Actions --- //
  const resetState = () => {
    // Internal state
    userAppSettingsFetchedRef.current = false;
    // Reducer state
    dispatch({
      type: APP_SETTINGS_RESET_STATE,
      payload: null,
    });
  };

  const setTrialSubscription = (subscription: AppConfigSubNames | null) => {
    dispatch({
      type: SET_TRIAL_SUBSCRIPTION,
      payload: subscription,
    });
  };

  const setUserLanguage = (lang: I18n) => {
    dispatch({
      type: SET_USER_LANGUAGE,
      payload: lang,
    });
  };

  const setMapBackgroundLayer = (layer: string) => {
    dispatch({
      type: SET_MAP_BACKGROUND_LAYER,
      payload: layer,
    });
  };

  const setMapOverlayLayers = (layers: string[]) => {
    dispatch({
      type: SET_MAP_OVERLAY_LAYERS,
      payload: layers,
    });
  };

  const setMapCategories = (categories: string[]) => {
    dispatch({
      type: SET_MAP_CATEGORIES,
      payload: categories,
    });
  };

  const setMapCategoriesFilterState = (categoryFilterState: any) => {
    dispatch({
      type: SET_MAP_CATEGORY_FILTER_STATE,
      payload: categoryFilterState,
    });
  };

  const setMapDisplayTotalValues = (displayTotalValues: boolean) => {
    dispatch({
      type: SET_MAP_DISPLAY_TOTAL_VALUES,
      payload: displayTotalValues,
    });
  };

  const setLabelsActive = (labels: {
    pointLabelsActive: boolean;
    lineLabelsActive: boolean;
    polygonLabelsActive: boolean;
  }) => {
    dispatch({
      type: SET_LABELS_ACTIVE,
      payload: labels,
    });
  };

  const setTooltipsActive = (tooltips: {
    pointTooltipsActive: boolean;
    lineTooltipsActive: boolean;
    polygonTooltipsActive: boolean;
  }) => {
    dispatch({
      type: SET_TOOLTIPS_ACTIVE,
      payload: tooltips,
    });
  };

  // --- Sync context with firestore --- //
  const fetchUserAppSettings = async (userId: string) => {
    try {
      const docRef = doc(db, `users/${userId}/private_data/appSettings`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // Set trial subscription
        const trialSubscription = data.trialSubscription ? data.trialSubscription : "unknown";
        dispatch({
          type: SET_TRIAL_SUBSCRIPTION,
          payload: trialSubscription,
        });
        // Set localization
        if (data.userLanguage) {
          dispatch({
            type: SET_USER_LANGUAGE,
            payload: data.userLanguage,
          });
          onChangeLocalization(data.userLanguage);
        }
        // Set background layer
        if (data.map.backgroundLayer) {
          dispatch({
            type: SET_MAP_BACKGROUND_LAYER,
            payload: data.map.backgroundLayer,
          });
        }
        // Set overalys
        if (data.map.overlayLayers) {
          dispatch({
            type: SET_MAP_OVERLAY_LAYERS,
            payload: data.map.overlayLayers,
          });
        }
        // Categories
        if (data.map.categories) {
          dispatch({
            type: SET_MAP_CATEGORIES,
            payload: data.map.categories,
          });
        }
        // Categories filter state
        if (data.map.categoryFilterState) {
          dispatch({
            type: SET_MAP_CATEGORY_FILTER_STATE,
            payload: data.map.categoryFilterState,
          });
        }
        // Set data display
        dispatch({
          type: SET_MAP_DISPLAY_TOTAL_VALUES,
          payload:
            data.map.displayTotalValues !== undefined
              ? data.map.displayTotalValues
              : initialState.map.displayTotalValues,
        });
        // Set labels
        dispatch({
          type: SET_LABELS_ACTIVE,
          payload: {
            pointLabelsActive:
              data.map.pointLabelsActive !== undefined
                ? data.map.pointLabelsActive
                : initialState.map.pointLabelsActive,
            lineLabelsActive:
              data.map.lineLabelsActive !== undefined
                ? data.map.lineLabelsActive
                : initialState.map.lineLabelsActive,
            polygonLabelsActive:
              data.map.polygonLabelsActive !== undefined
                ? data.map.polygonLabelsActive
                : initialState.map.polygonLabelsActive,
          },
        });
        dispatch({
          type: SET_TOOLTIPS_ACTIVE,
          payload: {
            pointTooltipsActive:
              data.map.pointTooltipsActive !== undefined
                ? data.map.pointTooltipsActive
                : initialState.map.pointTooltipsActive,
            lineTooltipsActive:
              data.map.lineTooltipsActive !== undefined
                ? data.map.lineTooltipsActive
                : initialState.map.lineTooltipsActive,
            polygonTooltipsActive:
              data.map.polygonTooltipsActive !== undefined
                ? data.map.polygonTooltipsActive
                : initialState.map.polygonTooltipsActive,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching user settings: ", error);
    }
    userIdRef.current = userId;
    dispatch({ type: SET_INIT_STATE, payload: false });
  };

  const updateUserAppSettings = async (userId: string, data: any) => {
    try {
      const docRef = doc(db, `users/${userId}/private_data/appSettings`);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        await setDoc(docRef, {
          ...data,
        });
      } else {
        await updateDoc(docRef, {
          ...data,
        });
      }
    } catch (error) {
      console.error("Error updating user settings: ", error);
    }
  };

  return (
    <AppSettingsContext.Provider
      value={{
        ...state,
        setTrialSubscription,
        setUserLanguage,
        setMapBackgroundLayer,
        setMapOverlayLayers,
        setMapCategories,
        setMapCategoriesFilterState,
        setMapDisplayTotalValues,
        setLabelsActive,
        setTooltipsActive,
        updateUserAppSettings,
        fetchUserAppSettings,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
}

export default AppSettingsContext;
