import { useNavigate } from "react-router-dom";
// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import Locales from "components/Locales";
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";

// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";

// app settings provider
import { AppSettingsProvider } from "contexts/AppSettingsContext";

// files provider
import { FilesProvider } from "contexts/FilesContext";

// Estates an maps data provider
import { EstatesAndMapsProvider } from "contexts/EstatesAndMapsContext";

// Map data provider
import { MapDataProvider } from "contexts/MapDataContext";

// Onboarding
import Onboarding from "features/onboarding";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AppSettingsProvider>
            <AuthProvider>
              <EstatesAndMapsProvider>
                <MapDataProvider>
                  <FilesProvider>
                    <>
                      <Onboarding />
                      <Routes />
                      <Snackbar />
                    </>
                  </FilesProvider>
                </MapDataProvider>
              </EstatesAndMapsProvider>
            </AuthProvider>
          </AppSettingsProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
