import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import config from 'config/config';
import useAuth from 'hooks/useAuth';
import { PAGES } from 'config/config-general';

// types
import { GuardProps } from 'types/auth';


// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
   // --- Hooks --- //
   const {
      isLoggedIn,
      urlParams,
   } = useAuth();
   const navigate = useNavigate();

   // --- Effects --- //
   useEffect(() => {
      if (isLoggedIn) {
         // Check for link to page in url parameters
         if (urlParams && urlParams.has("page")) {
            const page = urlParams.get("page");
            urlParams.delete("page");
            // Check page validity
            if (PAGES.includes(page)) {
               navigate(page, { replace: true });
            } else {
               console.log("HERE")
               navigate(config.defaultPath, { replace: true });
            }
         } else {
            navigate(config.defaultPath, { replace: true });
         }
      } else {
         if (urlParams && urlParams.has("audience")) {
            urlParams.delete("audience")
            navigate("anonymous-login", {replace: true});
         }
      }
   }, [isLoggedIn, navigate]);

   return children;
};

export default GuestGuard;
