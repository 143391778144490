import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import useConfig from "hooks/useConfig";

// assets
// import TranslateIcon from "@mui/icons-material/TranslateRounded";
import TranslateIcon from '@mui/icons-material/LanguageRounded';
import { I18n, lang } from "config/config-general";
import { useTranslation } from "utils/locales/utilityFunctions";
import useAppSettings from "hooks/useAppSettings";


// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
  // --- Hooks --- //
  const theme = useTheme();
  const { translate } = useTranslation();
  const { setUserLanguage } = useAppSettings();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const { i18n, onChangeLocalization } = useConfig();

  // --- State and refs --- //
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  // --- Effects --- //
  useEffect(() => {
    // Set initial language based on the path
    const path = window.location.pathname;
    const langAbbr = path.split("/")[2] as I18n;
    if (langAbbr && lang.includes(langAbbr)) {
      handleChangeLocalization(langAbbr);
    }
  }, []);
  

  // --- Functions --- //
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (lang: I18n) => {
    handleChangeLocalization(lang);
    setOpen(false);
  };

  const handleChangeLocalization = (lang: I18n) => {
    onChangeLocalization(lang);
    setUserLanguage(lang);
  };

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "primary.lighter";
  const iconBackColor =
    theme.palette.mode === "dark" ? "background.default" : "grey.100";
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open localization"
        ref={anchorRef}
        aria-controls={open ? "localization-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <TranslateIcon fontSize="small" color="primary" />
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom-start" : "bottom"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 0 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: "100%",
                    minWidth: 200,
                    maxWidth: 290,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    [theme.breakpoints.down("md")]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  {lang.map((item) =>
                    languageItem({
                      lang: item,
                      selected: i18n === item,
                      onClick: handleListItemClick,
                      translate,
                    })
                  )}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Localization;

// --- Helper functions --- //
type languageItemProps = {
  lang: I18n;
  selected: boolean;
  onClick: (lang: I18n) => void;
  translate: (key: string) => string;
};
function languageItem({
  lang,
  selected,
  onClick,
  translate,
}: languageItemProps) {
  return (
    <ListItemButton
      key={"language-item-" + lang}
      selected={selected}
      onClick={() => onClick(lang)}
      sx={{
        // Change bgcolor when hovering
        '&:hover': {
          bgcolor: 'primary.lighter', // Replace 'hoverColor' with your desired color
        },
        // Apply styles when `selected` is true
        '&.Mui-selected': {
          bgcolor: 'primary.lighter', // Replace 'selectedColor' with your desired color when selected
          // If you also want to change the hover color when selected
          '&:hover': {
            bgcolor: 'primary.lighter', // Replace 'selectedHoverColor' with your desired color on hover when selected
          },
        },
      }}
    >
      <ListItemText
        primary={
          <Grid container>
            <Typography color="textPrimary">
              {translate("language-" + lang)}
            </Typography>

            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ ml: "8px" }}
            >
              {lang.toUpperCase()}
            </Typography>
          </Grid>
        }
      />
    </ListItemButton>

    // <ListItemButton selected={selected} onClick={() => onClick(lang)}>
    //     <ListItemText
    //         primary={
    //             <Grid container>
    //                 <Typography color="textPrimary">{lang}</Typography>
    //             </Grid>
    //         }
    //     />
    // </ListItemButton>
  );
}
