// Types
import { OnboardingObject } from "types/auth";

export const mixpanelEvents = {
  estateCreated: "Estate Created", // Implemented
  estateDeleted: "Estate Deleted", // Implemented
  mapCreated: "Map Created", // Implemented
  mapDeleted: "Map Deleted", // Implemented
  projectCreated: "Project Created", // Implemented
  projectDeleted: "Project Deleted", // Implemented
  activityCreated: "Activity Created", // Implemented
  activityDeleted: "Activity Deleted", // Implemented
  orgToOrgSubscriptionCreated: "Org to Org Subscription Created", // Implemented
  orgToOrgSubscriptionDeleted: "Org to Org Subscription Deleted", // Implemented
  noteCreated: "Note Created", // Implemented
  noteDeleted: "Note Deleted", // Implemented
  fileUploaded: "File Uploaded", // Implemented
  fileDownloaded: "File Downloaded", // Implemented
  fileDeleted: "File Deleted", // Implemented
  mapLayerMenuOpened: "Map Layer Menu Opened", // Implemented
  mapFilterMenuOpened: "Map Filter Menu Opened", // Implemented
  mapFeatureOpened: "Map Feature Opened", // Implemented
  mapOrProjectShared: "Map or Project Shared", // Implemented
};

export function mapOnboardingStepToMixpanelUserOnbardingStep(onbObj: OnboardingObject): string {
  if (!onbObj || !onbObj.step) return "Step ?: Unknown";
  switch (onbObj.step) {
    case 0:
      return "Step 0: Welcome";
    case 1:
      return "Step 1: Heard about us";
    case 2:
      return "Step 2: Business info";
    case 3:
      return "Step 3: Questionnaire";
    case 4:
      return "Step 4: Finish";
    default:
      return "Step ?: Unknown";
  }
}
