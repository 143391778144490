// project import
import { useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

// material-ui
import {
   Box,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Menu,
   MenuItem,
   Stack,
   Typography,
   useMediaQuery,
   useTheme,
   IconButton
} from '@mui/material';

// material-ui icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

// project import
import Button from 'components/@extended/LoadingButton';
import HoldMenuItem from 'components/buttons/holdMenuItem';
import { useTranslation } from 'utils/locales/utilityFunctions';

type ChildFormDialogProps = {
   // Nested dialog
   open: boolean,
   title: string,
   text: string,
   onCancelText?: string,
   onClickText: string,
   matchDownMD: boolean,
   onClick?: () => void,
   onCancel?: () => void
}

type FormDialogProps = {
   // Dialog
   open: boolean,
   title: string,
   children: ReactJSXElement,
   loading?: boolean,
   saveDisabled?: boolean,
   captionText?: string,
   errorText?: string,
   onClickText?: string,
   onCancelText?: string,
   clickawayEnabled?: boolean,
   maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
   showDotMenu?: boolean,
   dotMenuItems?: { text: string, fcn: (e: any) => any, props?: any }[],
   onClick?: () => void,
   onCancel?: () => void,
   hideBackdrop?: boolean,

   // Nested dialog
   openNestedDialog?: boolean,
   titleNestedDialog?: string,
   textNestedDialog?: string,
   onClickNestedDialog?: () => void,
   onCancelNestedDialog?: () => void,
}

// Nested child dialog
function ChildDialog(props: ChildFormDialogProps) {
   return (
      <Dialog
         open={props.open}
         maxWidth='sm'
      >
         <Box sx={{ p: !props.matchDownMD ? 1 : 0 }}>
            <DialogTitle>
               {props.title}
            </DialogTitle>

            <DialogContent dividers>
               {props.text}
            </DialogContent>

            <DialogActions>
               <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: 1, px: 1.5, py: 0.75 }}>
                  <Button color="secondary" onClick={props.onCancel}>
                     {props.onCancelText}
                  </Button>

                  <Button variant="contained" onClick={props.onClick} sx={{ bgcolor: 'primary.light' }}>
                     {props.onClickText}
                  </Button>
               </Stack>
            </DialogActions>
         </Box>
      </Dialog>
   )
}

const FormDialog = (props: FormDialogProps) => {
   // --- Intl text --- //
   const { translate } = useTranslation();

   const theme = useTheme();

   const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const openDotMenuItems = Boolean(anchorEl);
   // Dialog for cancel warning etc.
   let openChildDialog = false;
   if (props.openNestedDialog) {
      openChildDialog = true;
   }

   useEffect(() => {
      if (!props.open && anchorEl) setAnchorEl(null);
   }, [props.open])

   // Dot menu actions
   const handleDotMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget)
   }

   const handleCloseDotMenuItems = () => {
      setAnchorEl(null);
   }

   return (
      <Dialog
         open={props.open}
         maxWidth={props.maxWidth}
         sx={{ '& .MuiDialog-paper': { width: '100%' } }}
         hideBackdrop={props.hideBackdrop}
         onClose={props.clickawayEnabled ? props.onCancel : undefined}
      >
         <Box sx={{ p: !matchDownMD ? 1 : 0 }}>
            <DialogTitle>
               {props.title}
            </DialogTitle>

            <DialogContent dividers>
               {props.captionText && <Box sx={{ pb: 2 }}><Typography variant="caption" color="GrayText">
                  {props.captionText}
               </Typography></Box>}

               {props.children}
            </DialogContent>

            <DialogActions>
               <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: 1, pl: 1.5, pr: props.showDotMenu ? 0.5 : 1.5, py: 0.75 }}>
                  {(props.errorText && props.errorText !== '') && <Typography variant="body1" sx={{ mb: 1 }}>
                     {props.errorText}
                  </Typography>}

                  {props.onCancel && <Button color="secondary" disabled={props.loading} onClick={props.onCancel}>
                     {props.onCancelText}
                  </Button>}

                  {props.onClick && <div className="form-dialog-main-button">
                     <Button
                        variant="contained"
                        disabled={props.saveDisabled || props.loading}
                        loading={props.loading}
                        sx={{
                           bgcolor: 'primary.light',
                           '&.Mui-disabled': {
                              bgcolor: 'secondary.lighter',
                              color: 'secondary.main'
                           }
                        }}
                        onClick={props.onClick}
                     >
                        {props.onClickText}
                     </Button>
                  </div>}

                  {props.showDotMenu && <IconButton disabled={props.loading} aria-label="settings" onClick={handleDotMenuClick}>
                     <MoreVertIcon fontSize='small' color="primary" />
                  </IconButton>}
               </Stack>
            </DialogActions>
         </Box>

         <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={openDotMenuItems}
            onClose={handleCloseDotMenuItems}
            MenuListProps={{
               'aria-labelledby': 'lock-button',
               role: 'listbox',
            }}
         >
            {props.dotMenuItems?.map((item, idx) => {
               // Check for type
               if (item.props && item.props.type && item.props.type === "hold") {
                  return <HoldMenuItem
                     key={"dot-menu-item-" + idx + "-" + item.text}
                     label={item.text}
                     disabled={item.props && item.props.disabled}
                     holdTime={item.props.time ? item.props.time : 1500}
                     onHoldComplete={() => item.fcn(null)}
                  />
               }
               // Default type
               return <MenuItem
                  key={"dot-menu-item-" + item.text}
                  disabled={item.props && item.props.disabled}
                  onClick={() => { item.fcn(null); handleCloseDotMenuItems() }}
                  sx={{
                     '&.Mui-selected': {
                        bgcolor: 'primary.lighter'
                     },
                     '&.MuiMenuItem-root:hover': {
                        bgcolor: 'secondary.lighter'
                     }
                  }}
               >
                  {item.text}
               </MenuItem>
            })}
         </Menu>

         <ChildDialog
            open={openChildDialog}
            title={translate("loss-of-data-title")}
            text={translate("loss-of-data-text")}
            onCancelText={translate("loss-of-data-proceed")}
            onClickText={translate("loss-of-data-cancel")}
            onClick={props.onClickNestedDialog}
            onCancel={props.onCancelNestedDialog}
            matchDownMD={matchDownMD}
         />
      </Dialog>
   )
}

export default FormDialog;