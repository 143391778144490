// action - state management
import {
   APP_SETTINGS_RESET_STATE,
   SET_TRIAL_SUBSCRIPTION,
   SET_USER_LANGUAGE,
   SET_MAP_BACKGROUND_LAYER,
   SET_MAP_OVERLAY_LAYERS,
   SET_MAP_CATEGORIES,
   SET_MAP_CATEGORY_FILTER_STATE,
   SET_LABELS_ACTIVE,
   SET_TOOLTIPS_ACTIVE,
   SET_INIT_STATE,
   SET_MAP_DISPLAY_TOTAL_VALUES,
} from "./actions";

// Utility
import { AppSettingsActionProps, AppSettingsProps } from "types/appSettings";
import { updateObject } from "utils/updateObject";

// Initial state
const initialState: AppSettingsProps = {
   initializing: true,
   trialSubscription: "unknown",
   userLanguage: null,
   map: {
      backgroundLayer: null,
      overlayLayers: null,
      categories: null,
      categoryFilterState: null,
      displayTotalValues: false,
      pointLabelsActive: true,
      lineLabelsActive: false,
      polygonLabelsActive: true,
      pointTooltipsActive: true,
      lineTooltipsActive: true,
      polygonTooltipsActive: true,
   },
}

const appSettings = (state = initialState, action: AppSettingsActionProps) => {

   const resetState = () => {
      return initialState;
   }

   const setTrialSubscription = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, { trialSubscription: action.payload });
   }

   const setUserLanguage = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, { userLanguage: action.payload });
   }

   const setMapBackgroundLayer = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            backgroundLayer: action.payload,
         }
      });
   }

   const setMapOverlayLayers = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            overlayLayers: action.payload,
         }
      });
   }

   const setMapCategories = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            categories: action.payload,
         }
      });
   }

   const setMapCategoryFilterState = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            categoryFilterState: action.payload,
         }
      });
   }

   const setMapDisplayTotalValues = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            displayTotalValues: action.payload,
         }
      });
   } 

   const setLabelsActive = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            ...action.payload,
         }
      });
   }

   const setTooltipsActive = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         map: {
            ...state.map,
            ...action.payload,
         }
      });
   }

   const setInitState = (state: AppSettingsProps, action: AppSettingsActionProps) => {
      return updateObject(state, {
         initializing: action.payload,
      });
   }

   switch (action.type) {
      case APP_SETTINGS_RESET_STATE: return resetState();
      case SET_TRIAL_SUBSCRIPTION: return setTrialSubscription(state, action);

      case SET_USER_LANGUAGE: return setUserLanguage(state, action);
      case SET_MAP_BACKGROUND_LAYER: return setMapBackgroundLayer(state, action);
      case SET_MAP_OVERLAY_LAYERS: return setMapOverlayLayers(state, action);

      case SET_MAP_CATEGORIES: return setMapCategories(state, action);
      case SET_MAP_CATEGORY_FILTER_STATE: return setMapCategoryFilterState(state, action);
      
      case SET_MAP_DISPLAY_TOTAL_VALUES: return setMapDisplayTotalValues(state, action);
      case SET_LABELS_ACTIVE: return setLabelsActive(state, action);
      case SET_TOOLTIPS_ACTIVE: return setTooltipsActive(state, action);

      case SET_INIT_STATE: return setInitState(state, action);

      default: return state;
   }
}

export default appSettings;