import React, { createContext, useEffect, useReducer } from 'react';
import { doc, setDoc, updateDoc, collection, query, where, onSnapshot, deleteDoc, DocumentReference } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { firestore as db, firebaseApp } from 'contexts/FirebaseContext';
import dayjs from 'dayjs';

// Project import
import {
   FILES_RESET_STATE,
   FILES_API_CALL_START,
   FILES_API_CALL_FAIL,
   ADDED_FILE_SUCCESS,
   MODIFIED_FILE_SUCCESS,
   REMOVED_FILE_SUCCESS,
   UPLOAD_FILE_SUCCESS,
   DOWNLOAD_FILE_SUCCESS,
   DELETE_FILE_SUCCESS,
} from "store/reducers/actions";
import filesReducer from 'store/reducers/files';
import { ERROR_REPORTING_EMAIL } from 'config/config-general';
import { DeleteFileProps, FetchFilesProps, FilesContextType, UploadFileProps, UploadFilesProps } from 'types/files';
import useEstatesAndMaps from 'hooks/useEstatesAndMaps';
import useAuth from 'hooks/useAuth';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from 'config/servicesConfig/mixpanelEvents';

// Firestore event listeners
let firestoreEventListeners: any[] = [];

const initialState = {
   loading: false,
   error: null,
   orgs: {},
   estates: {},
   maps: {},
   projects: {},
   activities: {},
}

const FilesContext = createContext<FilesContextType | null>(null);

export function FilesProvider({ children }: { children: React.ReactNode }) {
   const [state, dispatch] = useReducer(filesReducer, initialState);
   const { isLoggedIn, user, sendEmail } = useAuth();
   const { selectedMapId, selectedProjectId } = useEstatesAndMaps();

   // --- Reset state and unsubscribe from firestore listeners --- //
   useEffect(() => {
      return () => {
         dispatch({ type: FILES_RESET_STATE });
         firestoreEventListeners.forEach(unsubscribe => unsubscribe());
      }
   }, []);

   // --- Fetch files based on selected map and project id --- //
   useEffect(() => {
      if (isLoggedIn && selectedMapId && !state.maps[selectedMapId]) {
         fetchFiles({ rootFolder: "maps", rootId: selectedMapId });
      }
   }, [selectedMapId]);

   useEffect(() => {
      if (isLoggedIn && selectedProjectId && !state.projects[selectedProjectId]) {
         fetchFiles({ rootFolder: "projects", rootId: selectedProjectId });
      }
   }, [selectedProjectId]);

   // useEffect(() => {
   //    console.warn("FilesContext error: ", state.error);
   // }, [state.error]);

   // --- Fetch files --- //
   const fetchFiles = async ({ rootFolder, rootId }: FetchFilesProps) => {
      dispatch({ type: FILES_API_CALL_START });
      try {
         const q = query(collection(db, `${rootFolder}/${rootId}/files`));
         const unsubscribe = onSnapshot(q, (qs) => {
            qs.docChanges().forEach((change) => {
               let changeType = change.type;
               const data = change.doc.data();
               // Filter according to private category and createdByUserId, and internal and createdByOrgId
               if (data && data.featureCategories && data.uploadedBy) {
                  const {orgId, id} = data.uploadedBy;
                  if (data.featureCategories.includes("private") && id !== user?.id) changeType = "removed";
                  if (data.featureCategories.includes("internal") && orgId !== user?.orgId) changeType = "removed";
               }
               if (changeType === "added") {
                  dispatch({
                     type: ADDED_FILE_SUCCESS,
                     rootFolder,
                     rootId,
                     payload: data
                  });
               }
               if (changeType === "modified") {
                  dispatch({
                     type: MODIFIED_FILE_SUCCESS,
                     rootFolder,
                     rootId,
                     payload: data
                  });
               }
               if (changeType === "removed") {
                  dispatch({
                     type: REMOVED_FILE_SUCCESS,
                     rootFolder,
                     rootId,
                     payload: data
                  });
               }
            });
         });
         firestoreEventListeners.push(unsubscribe);
      } catch (error) {
         dispatch({ type: FILES_API_CALL_FAIL, error });
      }
   }

   // --- Upload multiple files --- //
   const uploadFiles = async ({ fileDoc, files, bucket, rootFolder, rootId }: UploadFilesProps) => {
      dispatch({ type: FILES_API_CALL_START });
      try {
         await Promise.all(files.map(async (file) => {
            return uploadFile({ fileDoc, file, bucket, rootFolder, rootId });
         }));
         dispatch({ type: UPLOAD_FILE_SUCCESS });
      } catch (error) {
         console.error("Error upload files", error);
         dispatch({ type: FILES_API_CALL_FAIL, error });
      }
   }

   // --- upload file --- //
   const uploadFile = async ({ fileDoc, file, bucket, rootFolder, rootId }: UploadFileProps) => {
      // Check for existing fileDoc and set refence to doc
      const { id } = fileDoc;
      let fileDocRef: DocumentReference;
      if (id) {
         // Update existing fileDoc
         fileDocRef = doc(db, `${rootFolder}/${rootId}/files/${id}`);
      } else {
         // Create new fileDoc
         fileDocRef = doc(collection(db, `${rootFolder}/${rootId}/files`));
      }
      // --- Upload file to firebase storage --- //
      const fileDocId = fileDocRef.id;
      const storage = getStorage(firebaseApp, bucket);
      const folderPath = `${rootFolder}/${rootId}/${fileDocId}:${file.name}`;
      const storageRef = ref(storage, folderPath);
      // Create file metadata including the content type
      const metadata = {
         contentType: file.type,
         customMetadata: {
            rootFolder,
            rootId,
            fileDocId,
         }
      };
      // Upload file and metadata to storage ref
      const uploadTask = await uploadBytes(storageRef, file, metadata);
      // Get download url
      const downloadUrl = await getDownloadURL(uploadTask.ref);
      // --- Save file document to firestore --- //
      const fileDocData = {
         ...fileDoc,
         type: file.type,
         name: file.name,
         size: file.size,
         id: fileDocId,
         lastModifiedDate: dayjs().format(),
         url: downloadUrl,
      }
      if (id) {
         await updateDoc(fileDocRef, fileDocData);
      } else {
         await setDoc(fileDocRef, {
            ...fileDocData,
            creationDate: dayjs().format(),
         });
         // Log event to mixpanel
         mixpanel.track(mixpanelEvents.fileUploaded);
      }
   }

   // --- download file --- //
   const downloadFile = async (fileUrl: string, fileName?: string) => {
      dispatch({ type: FILES_API_CALL_START });
      try {
         // Create an anchor tag dynamically
         const anchor = document.createElement('a');
         anchor.href = fileUrl;
         anchor.download = fileName || 'download';
         anchor.target = '_blank'; // Open in a new tab
         anchor.rel = 'noopener noreferrer'; // Security measure for opening links in a new tab
         document.body.appendChild(anchor); // Append to the document
         anchor.click(); // Trigger the open in new tab
         document.body.removeChild(anchor); //
         // Log event to mixpanel
         mixpanel.track(mixpanelEvents.fileDownloaded);
         dispatch({ type: DOWNLOAD_FILE_SUCCESS });
      } catch (error) {
         console.error('Download error:', error);
         dispatch({ type: FILES_API_CALL_FAIL, error });
      }
   }

   // --- delete file --- //
   const deleteFile = async ({ fileDocId, fileName, bucket, rootFolder, rootId }: DeleteFileProps) => {
      dispatch({ type: FILES_API_CALL_START });
      try {
         try {
            // --- Delete file from storage --- //
            const storage = getStorage(firebaseApp, bucket);
            const folderPath = `${rootFolder}/${rootId}/${fileDocId}:${fileName}`;
            const storageRef = ref(storage, folderPath);
            await deleteObject(storageRef);
         } catch (error) {
            sendEmail(
               ERROR_REPORTING_EMAIL,
               `[V2 App Error] - Error delete file from storage. filename: ${fileName}`,
               `File info:\n\n filename: ${fileName}\n fileDocId: ${fileDocId}\n rootFolder: ${rootFolder}\n rootId: ${rootId}\n\n Error: ${error} \n\n`
            )
         }

         // --- Delete file document from firestore --- //
         const fileDocRef = doc(db, `${rootFolder}/${rootId}/files/${fileDocId}`);
         await deleteDoc(fileDocRef);
         // Log event to mixpanel
         mixpanel.track(mixpanelEvents.fileDeleted);
         dispatch({ type: DELETE_FILE_SUCCESS })
      } catch (error) {
         console.error("Error delete file", error);
         dispatch({ type: FILES_API_CALL_FAIL, error });
      }
   }

   return (
      <FilesContext.Provider
         value={{
            ...state,
            fetchFiles,
            uploadFiles,
            uploadFile,
            downloadFile,
            deleteFile,
         }}>
         {children}
      </FilesContext.Provider>
   )
}
export default FilesContext;