import { useState } from "react";
// material-ui
import {
  Stack,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  OutlinedInput,
  FormHelperText,
  InputLabel,
} from "@mui/material";

// third-party
import { useFormik } from "formik";
import * as Yup from "yup";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

// project imports
import Button from "components/@extended/LoadingButton";
import { useTranslation } from "utils/locales/utilityFunctions";
import InputWithAction from "components/input/inputWithAction";
import useAuth from "hooks/useAuth";
import checkCompanyNumberFormat from "utils/checkCompanyNumber";
import useConfig from "hooks/useConfig";
import PlacesAutocomplete from "components/input/placesAutocomplete";
import { interpretPlaceDetails } from "lib/googleMaps/placesDataUtils";
import useStateRef from "react-usestateref";

// ==============================|| HOW DID YOU LEARN ABOUT US ||============================== //

interface FormValues {
  businessNo: string;
}

// const validationSchema: yup.SchemaOf<FormValues> = yup.object({
//    businessNo: yup.string()
//       .matches(/^\d{8}$/, "Det ser ud til, at du har indtastet en ugyldig værdi. Tjek og prøv igen.")
//       .required('Dette felt er obligatorisk. Indtast venligst den nødvendige information.')
// });

export type BusinessRegNo = {
  businessNo: string;
  data: any;
  businessExists: boolean;
};

interface LearnAboutUsProps {
  businessRegNo: BusinessRegNo;
  disableBackButton?: boolean;
  userOnHoldBusinessExists?: boolean;
  setBusinessRegNo: (d: BusinessRegNo) => void;
  handleNext: () => void;
  handleBack: () => void;
  setErrorIndex: (i: number | null) => void;
}

const CreateOrg = ({
  businessRegNo,
  disableBackButton,
  userOnHoldBusinessExists,
  setBusinessRegNo,
  handleNext,
  handleBack,
}: LearnAboutUsProps) => {
  // --- Hooks --- //
  const { translate } = useTranslation();
  const { callCloudFunction, user } = useAuth();
  const { i18n } = useConfig();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    // callbackName: "YOUR_CALLBACK_NAME",
    requestOptions: {
      /* Define search scope here */
      // componentRestrictions: {
      //    country: mapFromI18nToCountry(i18n),
      // }
      language: i18n,
    },
    debounce: 300,
  });

  // --- States and references --- //
  const [businessTempReg, setBusinessTempReg] = useState("");
  const [businessReg, setBusinessReg] = useState<string | null>(null);
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [businessExists, setBusinessExists] = useState(
    userOnHoldBusinessExists ? userOnHoldBusinessExists : false
  );
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [companyData, setCompanyData, companyDataRef] = useStateRef<any | null>(
    null
  );

  // Form validation
  const formik = useFormik({
    initialValues: {
      businessNo: businessRegNo.businessNo,
      businessName: "",
    },
    validationSchema: Yup.object().shape({
      businessNo: Yup.string().required(translate("business-number-required")),
      businessName: Yup.string()
        .max(255)
        .required(translate("business-name-required")),
    }),
    onSubmit: (values) => {
      const businessNo =
        businessTempReg !== "" ? businessTempReg : values.businessNo;
      setBusinessRegNo({
        businessNo: businessNo,
        data: { ...companyDataRef.current, name: values.businessName },
        businessExists: businessExists,
      });
      handleNext();
    },
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
  };

  const handleSelect = (event: any) => {
    formik.setFieldValue("businessName", "");
    formik.setFieldValue("businessNo", "");
    setValue("", false);
    clearSuggestions();
    getDetails({ placeId: event.place_id }).then((details) => {
      const data = interpretPlaceDetails(details);
      if (data.businessName) {
        formik.setFieldValue("businessName", data.businessName);
      }
      if (data.address) {
        const { streetName, streetNumber, locality, postalTown, postalCode, country } = data.address;
        const companyData = {
          address: "",
          city: "",
          zipcode: "",
          country: "",
        };
        if (streetName) {
          if (streetNumber) {
            companyData["address"] = streetName + " " + streetNumber;
          } else {
            companyData["address"] = streetName;
          }
        }
        if (locality) {
          companyData["city"] = locality;
        } else if (postalTown) {
          companyData["city"] = postalTown;
        }
        if (postalCode) companyData["zipcode"] = postalCode;
        if (country) companyData["country"] = country;

        setCompanyData({
          ...companyData,
        });
      }
    });
  };

  const handleChangeBusinessReg = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const regex = /^(?:[0-9]+)?$/;
    const lastChar = value.slice(-1);
    if (!regex.test(lastChar)) return;
    setBusinessTempReg(value);
  };

  async function onClickBusinessReg(businessRegNo: string) {
    try {
      setLoadingInternal(true);
      setSubmitError(null);
      setBusinessExists(false);
      const result: any = await callCloudFunction("getCompanyData", {
        businessRegNo: businessRegNo,
        countryCode: "da",
      });
      // Fill state based on result
      if (result.data) {
        const { data, status, message } = result.data;
        const companyData = data;
        // Indicate warning if org estate with given company number already exist
        if (status && status === "WARNING" && message === "existing/org") {
          setBusinessExists(true);
          setSubmitError(translate("onboarding-error-company-exists"));
          // setLoadingInternal(false);
          // return;
        }
        if (companyData) {
          const { name, vat } = companyData;
          setCompanyData(companyData);
          // Set data in state
          if (name)  {
            formik.setFieldValue("businessName", name);
            setCompanyName(name);
          }
          if (vat) {
            formik.setFieldValue("businessNo", vat);
            setBusinessReg(typeof vat === "number" ? vat.toString() : vat);
          }
        }
      }
    } catch (error: any) {
      console.error("Error getting company data", {
        error,
        message: error.message,
      });
      // setEstateSectionOpen(true)
      if (error.message && error.message === "Not Found") {
        setSubmitError(translate("onboarding-error-business-reg-not-found"));
      } else {
        setSubmitError(translate("error-company-data-default"));
      }
    }
    setLoadingInternal(false);
  }

  // If the user is on hold
  if (userOnHoldBusinessExists) {
    return (
      <DialogContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate("onboarding-company-title")}
        </Typography>

        <Typography variant="h6" sx={{ mb: 3 }}>
          {translate("onboarding-on-hold-business-exist-text")}
        </Typography>
      </DialogContent>
    );
  }

  // Create org based on country
  const contentSelector = (country: string | undefined, formik: any) => {
    if (!country) return defaultContentRender(formik);
    switch (country.toLowerCase()) {
      case "dk":
        return daContentRender();
      default:
        return defaultContentRender(formik);
    }
  };

  const companyAddressRender = (formik: any) => {
    if (!companyDataRef.current) return null;
    const { address, zipcode, city, country } = companyDataRef.current;
    // if (!address || !zipcode || !city || !country) {
    //   return (
    //     <Typography variant="h6">
    //       {translate("onboarding-company-address-not-found")}
    //     </Typography>
    //   );
    // }
    // Form company address
    let companyAddress = [];
    if (address) companyAddress.push(address);
    if (zipcode) companyAddress.push(zipcode);
    if (city) companyAddress.push(city);
    if (country) companyAddress.push(country);

    const { values, touched, errors, handleBlur, handleChange } = formik;
    return (
      <Stack spacing={3} sx={{ mt: 2 }}>
        <Stack spacing={0.5} sx={{ pt: 2 }}>
          <Typography sx={{ fontWeight: 500 }}>
            {translate("address")}
          </Typography>
          <Typography sx={{ whiteSpace: "pre-wrap" }} variant="h6">
            {companyAddress.join("\n")}
            {/* {companyDataRef.current.address +
              "\n" +
              companyDataRef.current.zipcode +
              ", " +
              companyDataRef.current.city +
              "\n" +
              companyDataRef.current.country} */}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <InputLabel htmlFor="business-name-onboarding">
            {translate("business-name")}*
          </InputLabel>
          <OutlinedInput
            id="business-name-onboarding"
            value={values.businessName}
            name="businessName"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={translate("business-name-placeholder")}
            fullWidth
            error={Boolean(touched.businessName && errors.businessName)}
            color="secondary"
          />
          {touched.businessName && errors.businessName && (
            <FormHelperText error id="helper-text-business-name-onboarding">
              {errors.businessName}
            </FormHelperText>
          )}
        </Stack>
        <Stack spacing={1}>
          <InputLabel htmlFor="business-number-onboarding">
            {translate("business-number")}*
          </InputLabel>
          <OutlinedInput
            id="business-number-onboarding"
            value={values.businessNo}
            name="businessNo"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={translate("business-number-placeholder")}
            fullWidth
            error={Boolean(touched.businessNo && errors.businessNo)}
            color="secondary"
          />
          {touched.businessNo && errors.businessNo && (
            <FormHelperText error id="helper-text-business-number-onboarding">
              {errors.businessNo}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    );
  };

  // Default content render if no country specific content is found
  const defaultContentRender = (formik: any) => {
    return (
      <>
        <Stack spacing={0.5}>
          <InputLabel htmlFor="business-search-address-onboarding">
            {translate("business-search-address-onboarding")}*
          </InputLabel>
          <PlacesAutocomplete
            value={value}
            placeholder={translate("search-bar-placeholder-text")}
            onChange={handleInput}
            onSelect={handleSelect}
            disabled={!ready}
            fullWidth={true}
            data={data}
            status={status}
          />

          {companyAddressRender(formik)}
        </Stack>
      </>
    );
  };

  const daContentRender = () => {
    return (
      <Stack spacing={0.5}>
        <InputWithAction
          placeholder={translate("onboarding-company-placeholder")}
          value={businessTempReg}
          fullWidth={true}
          handleChange={handleChangeBusinessReg}
          onClickAction={() => onClickBusinessReg(businessTempReg)}
          disabledAction={!checkCompanyNumberFormat(businessTempReg, "da")}
          showLabel={true}
          loading={loadingInternal}
          error={submitError !== null}
          helperText={
            submitError
              ? companyName
                ? companyName + ": " + submitError
                : submitError
              : companyName
              ? companyName
              : translate("onboarding-button-helpertext")
          }
        />
      </Stack>
    );
  };

  // Else return normally
  return (
    <>
      <DialogContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {translate("onboarding-company-title")}
        </Typography>

        <Typography variant="h6" sx={{ mb: 3 }}>
          {translate("onboarding-company-text")}
        </Typography>

        <Box sx={{ mb: 3 }}>
          <form onSubmit={formik.handleSubmit} id="validation-forms">
            {contentSelector(user?.countryCode, formik)}
          </form>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions>
        {/* ACTION */}
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          sx={{ width: 1, pl: 1.5, pr: 1.5, py: 0.75 }}
        >
          <Button
            color="secondary"
            disabled={disableBackButton || userOnHoldBusinessExists}
            onClick={handleBack}
          >
            {translate("back")}
          </Button>

          <Button
            variant="contained"
            type="button"
            disabled={
              (businessReg === null && formik.values.businessNo === "") ||
              userOnHoldBusinessExists
            }
            // disabled={!formik.isValid || formik.isSubmitting || (!formik.dirty && formik.values.businessNo === "")}
            // loading={formik.isSubmitting || loadingInternal}
            // loading={loadingInternal}
            onClick={() => {
              formik.handleSubmit();
            }}
            sx={{
              bgcolor: "primary.light",
              "&.Mui-disabled": {
                bgcolor: "secondary.lighter",
                color: "secondary.main",
              },
            }}
          >
            {businessExists
              ? translate("onboarding-request-org-access")
              : translate("next")}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

export default CreateOrg;
