// action - state management
import {
   // eslint-disable-next-line
   REGISTER,
   LOGIN, LOGOUT, IS_INITIALIZED, CHILDREN_IS_INITIALIZED,
   SET_CURRENT_SOFTWARE_VERSION,
   FETCH_USER_ORG_SUCCESS,
   FETCH_USER_ONBOARDING_OBJECT_SUCCESS,
   FETCH_ORG_PRIVATE_DATA_SUCCESS,
   FETCH_ORG_TO_ORG_PROVIDER_SUBSCRIPTIONS_SUCCESS,
   FETCH_ORG_TO_ORG_CONSUMER_SUBSCRIPTIONS_SUCCESS,
   DELETE_TEAM_MEMBER_FROM_STATE,
   FETCH_ORG_CONTACTS_SUCCESS,
   DELETE_CONTACT_FROM_STATE,
   SET_COUNTRY_CODES,
   SET_URL_PARAMS,
   SET_ERROR,
   SET_LOADING,
   ADDED_ORG_CONTACT_SUCCESS,
   MODIFIED_ORG_CONTACT_SUCCESS,
   REMOVED_ORG_CONTACT_SUCCESS,
   ORG_CONTACTS_EMPTY,
   ADDED_ORG_TEAM_MEMBER_SUCCESS,
   MODIFIED_ORG_TEAM_MEMBER_SUCCESS,
   REMOVED_ORG_TEAM_MEMBER_SUCCESS,
   FETCH_SPECIES_DATA_SUCCESS,
   FETCH_ACTIVITY_DATA_SUCCESS,
   FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS,
   FETCH_EXTERNAL_WMS_LAYERS_SUCCESS,
} from './actions';

// types
import { AuthProps, AuthActionProps, RoleTypes } from 'types/auth';

// initial state
export const initialState: AuthProps = {
   isLoggedIn: false,
   isInitialized: false,
   childrenInitialized: false,
   currentSoftwareVersion: "",
   loading: false,
   error: null,
   user: null,
   userOnboardingObject: null,
   userOrg: null,
   authUserOrgRole: "not-admin",
   orgPrivateData: {},
   orgToOrgProviderSubscriptions: null,
   orgTeam: {},
   orgContacts: {},
   urlParams: null,
   species: {},
   activityTypes: {},
   speciesOverwrites: null,
   subscriptionAddOns: null,
   externalWMSLayers: null,
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
   switch (action.type) {
      // case AUTH_RESET_STATE: return initialState;
      case REGISTER: {
         const { user } = action.payload!;
         return {
            ...state,
            user
         };
      }
      case LOGIN: {
         const { user } = action.payload!;
         return {
            ...state,
            isLoggedIn: true,
            // isInitialized: true,
            user
         };
      }
      case IS_INITIALIZED: {
         return {
            ...state,
            isInitialized: true
         };
      }
      case CHILDREN_IS_INITIALIZED: {
         return {
            ...state,
            childrenInitialized: true
         };
      }
      case SET_CURRENT_SOFTWARE_VERSION: {
         const { currentSoftwareVersion } = action.payload!;
         return {
            ...state,
            currentSoftwareVersion: currentSoftwareVersion
         }
      }
      case LOGOUT: {
         return {
            ...state,
            isInitialized: true,
            childrenIsInitialized: true,
            isLoggedIn: false,
            userOrg: null,
            authUserOrgRole: "not-admin" as RoleTypes,
            user: null,
            orgTeam: {},
            orgContacts: {},
            orgPrivateData: {},
            // urlParams: null,
            cloudFunctionLoading: false,
            cloudFunctionError: null,
         };
      }
      case FETCH_USER_ONBOARDING_OBJECT_SUCCESS: {
         const { userOnboardingObject } = action.payload!;
         return {
            ...state,
            userOnboardingObject: userOnboardingObject
         }
      }
      case FETCH_USER_ORG_SUCCESS: {
         const { userOrg } = action.payload!;
         return {
            ...state,
            loading: false,
            userOrg: userOrg,
         }
      }

      case FETCH_ORG_PRIVATE_DATA_SUCCESS: {
         const { orgPrivateData, authUserOrgRole } = action.payload!
         const updatedUser = { ...state.user };
         updatedUser.orgRole = authUserOrgRole;
         return {
            ...state,
            user: updatedUser,
            orgPrivateData: orgPrivateData,
         }
      }

      case FETCH_ORG_TO_ORG_PROVIDER_SUBSCRIPTIONS_SUCCESS: {
         const { orgToOrgProviderSubscriptions } = action.payload!;
         return {
            ...state,
            orgToOrgProviderSubscriptions: orgToOrgProviderSubscriptions
         }
      }

      case FETCH_ORG_TO_ORG_CONSUMER_SUBSCRIPTIONS_SUCCESS: {
         const { orgToOrgConsumerSubscriptions } = action.payload!;
         return {
            ...state,
            orgToOrgConsumerSubscriptions: orgToOrgConsumerSubscriptions
         }
      }

      case ADDED_ORG_TEAM_MEMBER_SUCCESS: {
         const memberData = action.payload!.memberData;
         let udpatedOrgTeam = { ...state.orgTeam };
         // Get member role
         let orgRole = "not-admin";
         if (state.orgPrivateData.roles && state.orgPrivateData.roles[memberData.id]) {
            orgRole = state.orgPrivateData.roles[memberData.id].role;
         }
         let name = memberData.firstName + " " + memberData.lastName;
         udpatedOrgTeam = {
            ...udpatedOrgTeam,
            [memberData.id]: { ...memberData, name: name !== " " ? name : "", orgRole }
         };
         return {
            ...state,
            orgTeam: udpatedOrgTeam,
         }
      }
      case MODIFIED_ORG_TEAM_MEMBER_SUCCESS: {
         const memberData = action.payload!.memberData;
         let udpatedOrgTeam = { ...state.orgTeam };
         // Get member role
         let orgRole = "not-admin";
         if (state.orgPrivateData.roles && state.orgPrivateData.roles[memberData.id]) {
            orgRole = state.orgPrivateData.roles[memberData.id].role;
         }
         let name = memberData.firstName + " " + memberData.lastName;
         udpatedOrgTeam[memberData.id] = { ...memberData, name: name !== " " ? name : "", orgRole }
         return {
            ...state,
            orgTeam: udpatedOrgTeam,
         }
      }
      case REMOVED_ORG_TEAM_MEMBER_SUCCESS: {
         const memberData = action.payload!.memberData;
         let udpatedOrgTeam = { ...state.orgTeam };
         delete udpatedOrgTeam[memberData.id];
         return {
            ...state,
            orgTeam: udpatedOrgTeam,
         }
      }

      case DELETE_TEAM_MEMBER_FROM_STATE: {
         const { removeUserId } = action.payload!;
         if (!removeUserId) return state;
         const udpatedOrgTeam = { ...state.orgTeam };
         delete udpatedOrgTeam[removeUserId];
         return {
            ...state,
            orgTeam: udpatedOrgTeam,
         }
      }
      case ADDED_ORG_CONTACT_SUCCESS: {
         const contactData = action.payload!.contactData;
         let udpatedOrgContacts = { ...state.orgContacts };
         // Add data
         udpatedOrgContacts = { ...udpatedOrgContacts, [contactData.id]: contactData }
         return {
            ...state,
            orgContacts: udpatedOrgContacts,
         }
      }
      case MODIFIED_ORG_CONTACT_SUCCESS: {
         const contactData = action.payload!.contactData;
         let udpatedOrgContacts = { ...state.orgContacts };
         udpatedOrgContacts[contactData.id] = contactData;
         return {
            ...state,
            orgContacts: udpatedOrgContacts,
         }

      }
      case REMOVED_ORG_CONTACT_SUCCESS: {
         const contactData = action.payload!.contactData;
         let udpatedOrgContacts = { ...state.orgContacts };
         delete udpatedOrgContacts[contactData.id];
         return {
            ...state,
            orgContacts: udpatedOrgContacts,
         }
      }
      case ORG_CONTACTS_EMPTY: {
         return {
            ...state,
            orgContacts: {}
         }
      }
      case FETCH_ORG_CONTACTS_SUCCESS: {
         const { orgContacts } = action.payload!;
         return {
            ...state,
            loading: false,
            orgContacts: orgContacts
         }
      }
      case DELETE_CONTACT_FROM_STATE: {
         const { removeUserId } = action.payload!;
         if (!removeUserId) return state;
         const udpatedOrgContacts = { ...state.orgContacts };
         delete udpatedOrgContacts[removeUserId];
         return {
            ...state,
            orgContacts: udpatedOrgContacts,
         }
      }
      case SET_COUNTRY_CODES: {
         const { countryCodes } = action.payload!;
         return {
            ...state,
            countryCodes: countryCodes
         }
      }
      case SET_URL_PARAMS: {
         const { urlParams } = action.payload!;
         return {
            ...state,
            urlParams: urlParams
         }
      }
      case FETCH_SPECIES_DATA_SUCCESS: {
         const { species } = action.payload!;
         return {
            ...state,
            species: species
         }
      }
      case FETCH_ACTIVITY_DATA_SUCCESS: {
         const { activityTypes } = action.payload!;
         return {
            ...state,
            activityTypes: activityTypes
         }
      }
      case FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS: {
         const { subscriptionAddOns } = action.payload!;
         return {
            ...state,
            subscriptionAddOns: subscriptionAddOns
         }
      }
      case FETCH_EXTERNAL_WMS_LAYERS_SUCCESS: {
         const { externalWMSLayers } = action.payload!;
         // const newLayers = {...state.externalWMSLayers, ...externalWMSLayers};
         const newLayers = externalWMSLayers;
         return {
            ...state,
            externalWMSLayers: newLayers,
         }
      }
      case SET_LOADING: {
         const { loading } = action.payload!;
         return {
            ...state,
            loading: loading
         }
      }
      case SET_ERROR: {
         const { error } = action.payload!;
         return {
            ...state,
            error: error,
            loading: false
         }
      }
      default: {
         return { ...state };
      }
   }
};

export default auth;
