// ==============================|| Software Version ||============================== //
export const SOFTWARE_VERSION = "2.6.12";

// ==============================|| Style CONFIG ||============================== //
export const drawerWidth = 260;

// export const twitterColor = '#1DA1F2';
// export const facebookColor = '#3b5998';
// export const linkedInColor = '#0e76a8';

// ==============================|| GENERAL APP CONFIG ||============================== //
export const ERROR_REPORTING_EMAIL = "app@skovkortet.dk";
export const DISABLE_AUDIENCE = true;
export const PAGES: string[] = ['home-page', 'map-page', 'files-page', 'projects-page', 'lists-page', 'account-page', 'estates-page'];
export const TBD_SPECIES = "To be determined";
export const lang = ['da', 'en', 'sv', 'no', 'de', 'es'] as const; // 'da' - Danish, 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
export type I18n = typeof lang[number];

// ==============================|| FILES CONFIG ||============================== //
export const DEFAULT_SUPPORTED_FILE_TYPES = ['JPG', 'JPEG', 'jpg', 'jpeg', 'png', 'webp', 'pdf', 'csv', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];
export const DEFAULT_SUPPORTED_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
export const DEFAULT_FILE_CATEGORIES = ["map", "budget-tracking", "invoices", "account-statements", "settlements", "other"] as const;
export type DEFAULT_FILE_CATEGORIES_TYPE = typeof DEFAULT_FILE_CATEGORIES[number];

// ==============================|| CATEGORIES CONFIG ||============================== //
export const DEFAULT_MAP_LAYERS = ["forest", "nature", "other"] as const;
export type DEFAULT_MAP_LAYERS_TYPE = typeof DEFAULT_MAP_LAYERS[number];
export const SEPCIAL_STYLE_CATEGORIES = ["intensive", "biodiversity", "untouched", "internal", "private"] as const;
export type SPECIAL_STYLE_CATEGORIES_TYPE = typeof SEPCIAL_STYLE_CATEGORIES[number];
export const DEFAULT_CATEGORIES = [...DEFAULT_MAP_LAYERS, ...SEPCIAL_STYLE_CATEGORIES, "audience", "hunting"] as const;
export type DEFAULT_CATEGORIES_TYPE = typeof DEFAULT_CATEGORIES[number];

// ==============================|| ACTIVITIES CONFIG ||============================== //
export const NEW_PROJECT_YEAR_RANGE  = 11;
export const DEFAULT_PROJECT_STATUS = ["active", "archived"] as const;
export type DEFAULT_PROJECT_STATUS_TYPE = typeof DEFAULT_PROJECT_STATUS[number];
export const DEFAULT_ACTIVITY_STATUS = ["not-decided", "not-started", "in-progress", "done"] as const;
export type DEFAULT_ACTIVITY_STATUS_TYPE = typeof DEFAULT_ACTIVITY_STATUS[number];
