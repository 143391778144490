

export function getShortestNestedArrayObject(array: any[], nestedArrayName: string) {
   return array.reduce((acc, obj) => {
      if (!acc || obj[nestedArrayName].length < acc[nestedArrayName].length) {
         return obj;
      }
      return acc;
   }, null);
}

export function getCommonElements<T>(array1: T[], array2: T[]): T[] {
   const commonElements: T[] = [];
   for (const element1 of array1) {
      if (array2.includes(element1)) {
         commonElements.push(element1);
      }
   }
   return commonElements;
}

export function hasCommonElement<T>(array1: T[], array2: T[]): boolean {
   for (const element1 of array1) {
      if (array2.includes(element1)) {
         return true; // Found a common element
      }
   }
   return false; // No common elements found
}

export function addObjectIfNotExists(array: any[], newObj: any) {
   // Check if an object with the same key-value pairs exists in the array
   const exists = array.some((obj) => {
      // Compare key-value pairs of newObj and obj
      for (const key in newObj) {
         if (newObj.hasOwnProperty(key) && obj.hasOwnProperty(key)) {
            if (newObj[key] !== obj[key]) {
               return false; // At least one key-value pair doesn't match
            }
         }
      }
      return true; // All key-value pairs match
   });

   // If no matching object was found, add newObj to the array
   if (!exists) {
      array.push(newObj);
   }
}

// Remove undefined elements from object
export function dataObjectWithoutUndefinedFields(obj: any) {
   return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== undefined)
   );
}

// Get difference between two arrays
export function getArrayDifference<T>(array1: T[], array2: T[]): T[] {
   return array1.filter((element) => !array2.includes(element));
}