import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

// scroll bar
import 'simplebar/src/simplebar.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// project import
import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import { AppFunctionalityProvider } from 'contexts/AppFunctionalityContext';
// import reportWebVitals from './reportWebVitals';


const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     networkMode: "always",
  //   },
  //   mutations: {
  //     networkMode: "always",
  //   },
  // },
});

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
root.render(
    <ReduxProvider store={store}>
        <ConfigProvider>
            <AppFunctionalityProvider>
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <App />
                        {/* <ReactQueryDevtools/> */}
                    </QueryClientProvider>
                </BrowserRouter>
            </AppFunctionalityProvider>
        </ConfigProvider>
    </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
